export const PRIMARY_COLOR = 'primary';
export const SECONDARY_COLOR = 'secondary';

export const DEFAULT_COLOR = 'default';
export const ERROR_COLOR = 'error';
export const INFO_COLOR = 'info';
export const SUCCESS_COLOR = 'success';
export const WARNING_COLOR = 'warning';
export const INHERIT_COLOR = 'inherit';

export const CONTAINED_VARIANT = 'contained';
export const OUTLINED_VARIANT = 'outlined';
export const BODY1_VARIANT = 'body1';
export const TEXT_VARIANT = 'text';
export const H1_VARIANT = 'h1';
export const H3_VARIANT = 'h3';
export const H4_VARIANT = 'h4';
export const H5_VARIANT = 'h5';
export const H6_VARIANT = 'h6';

export const EXTENDED_VARIANT = 'extended';
export const MIDDLE_VARIANT = 'middle';
export const PERSISTENT_VARIANT = 'persistent';
export const FILLED_VARIANT = 'filled';

export const BODY2 = 'body2';

export const SMALL_SIZE = 'small';
export const MEDIUM_SIZE = 'medium';
export const LARGE_SIZE = 'large';

export const DEFAULT = 'default';
export const INLINE = 'inline';
export const NONE = 'none';
export const EMPTY = '';

// alignment and positioning
export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';
export const START = 'start';
export const END = 'end';
export const CENTER_LARGE = `${CENTER}_large`;
export const ALIGNMENT_OPTIONS = [LEFT, CENTER, RIGHT];
