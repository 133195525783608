import { useSelector } from 'react-redux';

import {
  selectAdjectiveWords,
  selectContentSet,
} from '@glass/shared/modules/content/contentReducer';
import isContentWordEqual from '@glass/shared/modules/content/staticVariations/isContentWordEqual';
import usePageData from '@glass/shared/modules/pageData/usePageData';

const useAdjectiveWords = () => {
  const reduxResumeWords = useSelector(selectAdjectiveWords, isContentWordEqual);
  const isContentSet = useSelector(selectContentSet);
  const { words } = usePageData() || {};
  return !isContentSet && words?.adjectiveWords ? words.adjectiveWords : reduxResumeWords;
};

export default useAdjectiveWords;
