import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { LEGAL_PATHNAME, LEGAL_TERMS_SECTION } from '@glass/common/modules/pages/paths';

import Link from '@glass/shared/components/Link/Link';

const LegalLink = React.forwardRef(
  ({ className, section, pageType, children, target, shallow, onClick }, ref) => {
    const href = useMemo(
      () => ({
        pathname: LEGAL_PATHNAME,
        query: {
          sections: [pageType, section].filter(Boolean),
        },
      }),
      [section, pageType],
    );

    return (
      <Link
        ref={ref}
        className={className}
        href={href}
        shallow={shallow}
        target={target}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  },
);

LegalLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  section: PropTypes.string,
  shallow: PropTypes.bool,
  target: PropTypes.string,
};

LegalLink.defaultProps = {
  section: LEGAL_TERMS_SECTION,
};

export default React.memo(LegalLink);
