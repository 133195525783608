import { useCallback, useMemo, MouseEventHandler, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { selectRouteOnBlock } from '@glass/web/modules/app';

const useBlockClick = (
  onClick: MouseEventHandler<HTMLAnchorElement>,
  { preventBlock }: { preventBlock?: boolean } = {},
) => {
  const onBlock = useSelector(selectRouteOnBlock);
  const isBlocked = useMemo(
    () => typeof onBlock === 'function' && !preventBlock,
    [onBlock, preventBlock],
  );
  const handleClick = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      if (isBlocked) {
        evt.preventDefault();
        if (typeof onBlock === 'function') {
          onBlock(evt);
        }
        return false;
      }
      if (typeof onClick === 'function') {
        onClick(evt);
      }
      return null;
    },
    [isBlocked, onClick, onBlock],
  );
  return { handleClick, isBlocked };
};

export default useBlockClick;
