import { selectUser } from '@glass/web/modules/auth';
import getBrowserLanguage from '@glass/web/modules/locales/getBrowserLanguage';
import getBrowserTimezone from '@glass/web/modules/locales/getBrowserTimezone';

import createUserMutation from '@glass/shared/modules/auth/queries/createUser.graphql';
import signInAuthAction from '@glass/shared/modules/auth/signInAuthAction';

// returns an authenticated user
const createUserAction =
  ({ email, password, profile, acceptedTermsAt }) =>
  async (dispatch, getState, { client, tracking }) => {
    const user = selectUser(getState());
    if (user) {
      return Promise.resolve(user);
    }
    return client
      .mutate({
        mutation: createUserMutation,
        variables: {
          email,
          password,
          profile,
          acceptedTermsAt,
          browserLanguage: getBrowserLanguage(),
          browserTimezone: getBrowserTimezone(),
        },
      })
      .then(({ data }) => {
        tracking.createAuth();
        return dispatch(signInAuthAction(data.createUser));
      });
  };

export default createUserAction;
