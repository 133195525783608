import { EMAIL } from '@glass/common/modules/tracking/constants/labels';

import sendCommunicationMutation from '@glass/shared/modules/auth/queries/sendCommunication.graphql';
import Tracking from '@glass/shared/modules/tracking/Tracking';

const sendCommunicationAction =
  ({ emailId, email, options }) =>
  (_dispatch, _getState, { client, tracking }) => {
    const trackCommunication = (props = null) =>
      tracking.communication({
        action: Tracking.ACTIONS.SUCCESS,
        label: EMAIL,
        ...props,
        emailDestination: email,
        emailId,
        options,
      });

    return client
      .mutate({
        mutation: sendCommunicationMutation,
        variables: {
          email,
          emailId,
          options,
        },
      })
      .then(({ data }) => {
        trackCommunication({ data });
        return data.sendEmail;
      })
      .catch((err) => {
        trackCommunication({
          action: Tracking.ACTIONS.FAIL,
          label: EMAIL,
          message: err.message,
        });
        throw err;
      });
  };

export default sendCommunicationAction;
