import createOnHandler from '@glass/common/modules/utils/createOnHandler';

import { CREATE_ACCOUNT_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import createUserAction from '@glass/shared/modules/auth/createUserAction';
// eslint-disable-next-line import/no-cycle
import handleCreateUserErrorAction from '@glass/shared/modules/authForm/actions/handleCreateUserErrorAction';
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { PASSWORD_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const createEmailSignupAction =
  (options) =>
  ({ email, profile, acceptedTermsAt }) =>
  (dispatch) => {
    const { requirePassword, onAuth } = options;
    return dispatch(createUserAction({ email, profile, acceptedTermsAt }))
      .then((user) => {
        if (requirePassword) {
          return dispatch(
            promptAuthAction({
              email: user.email,
              view: PASSWORD_AUTH_VIEW,
              actionType: CREATE_ACCOUNT_ACTION,
            }),
          );
        }
        return createOnHandler(onAuth)(user);
      })
      .catch((error) =>
        dispatch(handleCreateUserErrorAction(error, options, { email, profile, acceptedTermsAt })),
      );
  };

export default createEmailSignupAction;
