import React from 'react';

import makeStyles from '@glass/web/modules/theme/makeStyles';

export const NATURAL_WIDTH = 190;
export const NATURAL_HEIGHT = 89;

type LogoProps = {
  color?: string;
  className?: string;
};

const useStyles = makeStyles()({
  svg: {
    marginTop: '-2.5%',
    fill: 'currentColor',
    aspectRatio: NATURAL_WIDTH / NATURAL_HEIGHT,
    width: '100%',
  },
});

function Logo({ className }: LogoProps) {
  const { classes } = useStyles();
  return (
    <div className={className}>
      <svg
        className={classes.svg}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1576 740"
      >
        <g>
          <path d="M235.2,303.3c-3.6-49.7-36.1-64.1-71.3-65.9c-35.2-1.8-112-0.9-112-0.9s-25.3-5.4-25.3,31.6c0,32-16.9,195.7-21.4,239.4c-0.8,8,1.8,16.1,7.4,21.9c6.8,6.9,17.3,13.6,30.3,9.6c23.5-7.2,43.3-22.6,39.7-113.8c0,0,50.6-13.5,62.3,34.3c11.3,46,11.7,90.4,73.6,73.6c4.5-1.2,7.5-5.5,7.2-10.2c-1.6-23.8-9.2-95.2-42-134.7C183.7,388.2,238.8,353,235.2,303.3z M89.8,358.4c-8.1-5.4,0.9-64.1,0.9-64.1c6.3-10.8,60.5-22.6,68.6,21.7C167.4,360.2,97.9,363.8,89.8,358.4z" />
          <path d="M543.9,358.4c-2.7-61.4-25.3-121-126.4-128.2c0,0-125.5,3.6-146.3,119.2s8.1,197.7,130,194.1S546.7,419.8,543.9,358.4z M400.4,483.9c-47.9,9-78.6-42.4-64.1-115.6c14.4-73.1,71.3-78.6,71.3-78.6c35.2,1.8,66.8,12.6,67.7,83.1C476.2,443.3,448.2,474.9,400.4,483.9z" />
          <path d="M805.8,242.8c0,0-101.1-32.5-158,6.3c-56.9,38.8-93,124.6-69.5,200.4c23.5,75.8,58.7,92.1,133.6,92.1s289.8-177,338.6-303.4c0,0-8.1-16.3-43.3,28.9s-78.6,80.4-78.6,80.4l3.6-85.8c0,0,0.9-31.6-35.2-28.9c-36.1,2.7-33.4,9-38.8,55.1c-5.4,46-9.9,126.4-9.9,126.4s-63.2,69.5-130.9,65s-85.8-45.1-67.7-115.6c18.1-70.4,65.9-78.6,116.5-65.9C816.6,310.5,833.8,257.3,805.8,242.8z" />
          <path d="M907.8,449.6c0,0-65.9,50.6-67.7,61.4c-1.8,10.8-4.5,26.2,9,26.2c13.5,0,60.5,7.2,60.5-13.5C909.6,502.8,907.8,449.6,907.8,449.6z" />
          <path d="M980,378.2c0,0-46.9,45.1-43.3,51.5s43.3,102,55.1,105.6c11.7,3.6,82.2,6.3,73.1-8.1C1055.9,512.8,980,378.2,980,378.2z" />
          <path d="M1199.4,18c0,0-65,3.6-112.9,110.2c0,0-0.9,5.4-14.4,9.9c-13.5,4.5-19.9,16.3-28,28.9c0,0,39.7-8.1,52.4,5.4s10.8,46.9,10.8,46.9s31.3-12.8,28.8-48.4c-0.3-4.3,1.7-8.4,5-11.2C1158.2,145.7,1206.1,98.4,1199.4,18zM1122.7,166.1c5.4,31.6-5.4,17.2-5.4,17.2c-4.5-15.3-19.9-22.6-15.3-22.6s85.8-119.2,85.8-119.2C1168.7,112.8,1122.7,166.1,1122.7,166.1z" />
          <path d="M1152.5,231.1c0,0-36.1-4.5-40.6,22.6s-21.7,255.5-21.7,255.5s-1.8,22.6,19,23.5c20.8,0.9,158,15.3,163.4-8.1c5.4-23.5,9-45.1-12.6-46.9c-21.7-1.8-95.7,9-99.3-13.5c-3.6-22.6,3.6-55.1,24.4-54.2c20.8,0.9,89.4,12.6,91.2-13.5c1.8-26.2,3.6-40.6-12.6-41.5c-16.3-0.9-94.7,3-93.9-17.2c0.9-23.5-3.6-45.1,36.1-47.9c39.7-2.7,94.8,13.5,98.4-18.1c3.6-31.6-9-36.1-46-39.7C1221.1,228.4,1152.5,231.1,1152.5,231.1z" />
          <path d="M1343.9,234.7c0,0-21.7-4.5-23.5,13.5c-1.8,18.1-4.5,39.7,12.6,41.5c17.2,1.8,62.3-6.3,60.5,9s-19,211.3-19,211.3s-4.5,22.6,14.4,21.7c19-0.9,48.8,12.6,53.3-49.7s14.4-158.9,14.4-158.9s0-30.7,20.8-28.9s67.7,11.7,71.3-30.7c0,0,6.3-27.1-19.9-28S1343.9,234.7,1343.9,234.7z" />
          <path d="M364.3,631.1c0-22.4-15.4-32.4-26.6-36.7c-5.4-2.1-11.2-3-17.1-3l-64.7,0.8c-1.6,0-2.9,1.1-3.2,2.7l-28.4,140.9l32.5-1.8l9.9-51.5c0,0,23.5-9.9,36.1,30.7l2.7,18.1h30.7c0,0-2.7-28.9-16.3-48.8C320,682.5,364.3,666.3,364.3,631.1z M274.9,651.8l6.3-33.4c0,0,40.6-13.5,46,12.6C332.7,657.2,274.9,651.8,274.9,651.8z" />
          <path d="M431.4,595.1l-26.5,131.7c-0.5,2.3,1.3,4.4,3.7,4.4l93.3-0.8c1.9,0,3.4-1.4,3.6-3.3l1.9-16.1c0.3-2.2-1.5-4.1-3.7-4.1l-58.6,0.8c-2.5,0-4.3-2.3-3.6-4.7l7-25.1c0.4-1.6,1.9-2.7,3.6-2.7h59.4c1.6,0,3.1-1.1,3.5-2.6l5-17c0.7-2.4-1.1-4.7-3.5-4.7h-57.5c-2.3,0-4-2.1-3.6-4.3l4.2-24.3c0.3-1.8,1.8-3,3.6-3.1l63.4-0.8c1.8,0,3.2-1.3,3.6-3l4-20.6c0.4-2.3-1.3-4.4-3.7-4.4l-95.4,1.7C433.2,592.2,431.7,593.4,431.4,595.1z" />
          <path d="M685.4,625.9l13.9-13.9c1-1,1-2.5-0.1-3.5c-6.4-5.9-29.3-24.8-56-21.7c-31.6,3.6-50.6,7.2-56,38.8c-5.2,30.3,52.8,53.2,57.5,55c0.2,0.1,0.4,0.2,0.6,0.3c2.8,2.2,25.7,21-6.6,28.7c-27.9,6.6-45-10.6-50.5-17.3c-1-1.2-2.9-1.2-3.8,0.1l-13.1,18.1c-0.7,1-0.6,2.4,0.4,3.2c8,6.9,44.3,35,84.1,17.5c45.1-19.9,38.8-57.8,21.7-67.7c-17.2-9.9-62.3-24.4-60.5-41.5c1.7-16.1,55.6-9.2,64.7,3.6C682.6,626.9,684.4,627,685.4,625.9z" />
          <path d="M775.4,589.9c-1.8-0.2-3.5,0.9-4,2.6c-6.6,19.7-41.6,133.2,23.6,140.7c70.1,8,90.5-48.5,105.7-138.7c0.4-2.5-1.6-4.7-4.1-4.5L871,592c-1.7,0.1-3.1,1.4-3.4,3.1c-3.2,18.2-22.1,116.6-57.3,110.9c-37.8-6.2-23.4-53.4-6.7-108.5c0.7-2.3-0.9-4.6-3.2-4.9L775.4,589.9z" />
          <path d="M936.3,725l34-130.8c0.4-1.7,1.9-2.8,3.6-2.8h38.1c1.9,0,3.5,1.4,3.7,3.3l10.4,77.1l49.5-75.9c0.7-1.1,1.9-1.7,3.1-1.7h42.9c2.4,0,4.2,2.3,3.7,4.6l-30.6,130.2c-0.5,2-2.2,3.3-4.2,3.3H1065c-2.8,0-4.9-2.7-4.2-5.4l22.1-87.6l-66.9,90.9c-1.4,1.9-4.3,1.2-4.8-1l-18.7-91.6L971.8,727c-0.5,2-2.2,3.3-4.2,3.3h-27.1C937.7,730.4,935.6,727.7,936.3,725z" />
          <path d="M1189.8,598.8l-26.5,131.7c-0.5,2.3,1.3,4.4,3.7,4.4l93.3-0.8c1.9,0,3.4-1.4,3.6-3.3l1.9-16.1c0.3-2.2-1.5-4.1-3.7-4.1l-58.6,0.8c-2.5,0-4.3-2.3-3.6-4.7l7-25.1c0.4-1.6,1.9-2.7,3.6-2.7h59.4c1.6,0,3.1-1.1,3.5-2.6l5-17c0.7-2.4-1.1-4.7-3.5-4.7h-57.5c-2.3,0-4-2.1-3.6-4.3l4.2-24.3c0.3-1.8,1.8-3,3.6-3.1l63.4-0.8c1.8,0,3.2-1.3,3.6-3l4-20.6c0.4-2.3-1.3-4.4-3.7-4.4l-95.4,1.7C1191.6,595.8,1190.2,597.1,1189.8,598.8z" />
        </g>
      </svg>
    </div>
  );
}

export default React.memo(Logo);
