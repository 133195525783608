import { useRouter } from 'next/router';
import { useMemo } from 'react';

import {
  BASE_PATH_2_QUERY,
  INDUSTRY_SLUG_QUERY,
  JOBTITLE_SLUG_QUERY,
} from '@glass/common/modules/pages/queryParams';

import usePageData from '@glass/shared/modules/pageData/usePageData';

const usePageTerms = () => {
  const {
    query: {
      [BASE_PATH_2_QUERY]: industrySlug,
      [INDUSTRY_SLUG_QUERY]: iSlugProp,
      [JOBTITLE_SLUG_QUERY]: jSlugProp,
    },
  } = useRouter();

  const { industry, jobTitle } = usePageData() || {};
  const iSlug = industry?.slug || iSlugProp || industrySlug || jobTitle?.industries?.[0]?.slug;
  const jSlug = jobTitle?.slug || jSlugProp;
  const pageTerm = jobTitle?.name || industry?.name;

  return useMemo(
    () => ({
      iSlug,
      jSlug,
      pageTerm,
    }),
    [iSlug, jSlug, pageTerm],
  );
};

export default usePageTerms;
