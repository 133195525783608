import { useSelector } from 'react-redux';

import { selectContentSet, selectResumeWords } from '@glass/shared/modules/content/contentReducer';
import isContentWordEqual from '@glass/shared/modules/content/staticVariations/isContentWordEqual';
import usePageData from '@glass/shared/modules/pageData/usePageData';

const useResumeWords = () => {
  const reduxResumeWords = useSelector(selectResumeWords, isContentWordEqual);
  const isContentSet = useSelector(selectContentSet);
  const { words } = usePageData() || {};
  return !isContentSet && words?.resumeWords ? words.resumeWords : reduxResumeWords;
};

export default useResumeWords;
