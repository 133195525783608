import { useCallback, useEffect } from 'react';

import Tracking from '@glass/shared/modules/tracking/Tracking';
import useTracking from '@glass/shared/modules/tracking/useTracking';

const EVENT_FNS = {
  [Tracking.EVENTS.CLICK]: (evt) => {
    const { pageX, pageY, clientX, clientY, target: { innerText } = {} } = evt || {};
    return {
      pageX,
      pageY,
      clientX,
      clientY,
      innerText,
    };
  },
};

const useFunctionTracking = (event, trackProps, onEventFn) => {
  useEffect(() => {
    if (!Object.values(Tracking.EVENTS).includes(event)) {
      throw new Error(`${event} is not a valid event passed to useFunctionTracking`);
    }
  }, [event]);
  const tracking = useTracking();
  return useCallback(
    (...args) => {
      const sendTrackProps = {
        ...(typeof trackProps === 'function' ? trackProps(...args) : trackProps),
        ...(EVENT_FNS[event]?.(...args) || {}),
      };

      tracking.track(event, sendTrackProps);
      if (typeof onEventFn === 'function') {
        return onEventFn(...args);
      }
      return null;
    },
    [tracking, event, trackProps, onEventFn],
  );
};

export default useFunctionTracking;
