import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import MuiButton from '@glass/web/components/base/Button';

import Tracking from '@glass/shared/modules/tracking/Tracking';
import useFunctionTracking from '@glass/shared/modules/tracking/useFunctionTracking';

const useAriaLabel = ({ children }) =>
  useMemo(() => {
    if (typeof children === 'string') {
      return children;
    }
    return 'button';
  }, [children]);

const Button = React.forwardRef(function Button(
  {
    variant,
    color,
    className,
    trackProps: trackPropsProp,
    onClick,
    ariaLabel: ariaLabelProp,
    children,
    disableElevation,
    ...otherProps
  },
  ref,
) {
  const ariaLabel = useAriaLabel({ children });

  const trackProps = useCallback(
    (...args) => ({
      action: 'button',
      ...(typeof trackPropsProp === 'function' ? trackPropsProp(...args) : trackPropsProp),
    }),
    [trackPropsProp],
  );

  const handleClickTracked = useFunctionTracking(Tracking.EVENTS.CLICK, trackProps, onClick);

  return (
    <MuiButton
      aria-label={ariaLabelProp || ariaLabel}
      {...otherProps}
      ref={ref}
      className={className}
      color={color}
      disableElevation={disableElevation}
      variant={variant}
      onClick={handleClickTracked}
    >
      {children}
    </MuiButton>
  );
});

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disableElevation: PropTypes.bool,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  variant: PropTypes.string,
};

Button.defaultProps = {
  ariaLabel: 'button',
  trackProps: null,
  disableElevation: true,
};

Button.displayName = 'Button';

export default Button;
