import { CurrencyConfig } from '@glass/common/modules/payments/currencies/types';

const formatNumberDecimals = (
  amount: string | number | null,
  { decimals }: Pick<CurrencyConfig, 'decimals'>,
) => {
  if (
    (typeof amount !== 'number' && typeof amount !== 'string') ||
    typeof decimals === 'undefined'
  ) {
    return '';
  }

  const centsNumber = typeof amount === 'string' ? Number(amount) : amount;

  if (Number.isNaN(centsNumber) || !Number.isFinite(centsNumber)) {
    return '';
  }

  return (centsNumber / 10 ** decimals).toFixed(decimals);
};

export default formatNumberDecimals;
