import { useCallback, useMemo } from 'react';

import capitalizeFirstLetter from '@glass/common/modules/utils/capitalizeFirstLetter';
import extendText from '@glass/common/modules/utils/extendText';
import makeNbsp from '@glass/common/modules/utils/makeNbsp';
import formatCurrencyExtend from '@glass/common/modules/utils/numbers/formatCurrencyExtend';

import useAdjectiveWords from '@glass/shared/modules/content/staticVariations/adjectiveWords/useAdjectiveWords';
import useContentWords from '@glass/shared/modules/content/staticVariations/contentWords/useContentWords';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import usePageTerms from '@glass/shared/modules/content/staticVariations/usePageTerms';
import useVerbWords from '@glass/shared/modules/content/staticVariations/verbWords/useVerbWords';
import usePageData from '@glass/shared/modules/pageData/usePageData';

const ACTIONS = {
  capitalizeFirstLetter,
  nbsp: makeNbsp,
  formatCurrency: formatCurrencyExtend,
};

const useExtendTextData = () => {
  const resumeWords = useResumeWords();
  const verbWords = useVerbWords();
  const contentWords = useContentWords();
  const adjectiveWords = useAdjectiveWords();
  const pageData = usePageData();
  const pageTerms = usePageTerms();

  const data = useMemo(
    () => ({
      brand: {
        title: process.env.NEXT_PUBLIC_BRAND_TITLE,
      },
      resumeWords,
      verbWords,
      contentWords,
      adjectiveWords,
      pageData,
      pageTerms,
    }),
    [adjectiveWords, contentWords, pageData, pageTerms, resumeWords, verbWords],
  );

  const extendTextFn = useCallback((string) => extendText(string, data, ACTIONS), [data]);

  return { extendText: extendTextFn, data };
};

export default useExtendTextData;
