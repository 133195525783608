import Typography, { type TypographyProps } from '@glass/web/components/base/Typography';

export const EXTRA_SMALL_FONT_SIZE = 11;

type CaptionTypographyProps = Pick<
  TypographyProps,
  'color' | 'component' | 'fontSize' | 'lineHeight' | 'align' | 'display' | 'children'
>;

function CaptionTypography({
  children,
  color = 'text.secondary',
  component = 'div',
  fontSize = '12px',
  lineHeight = '17.4px',
  align = 'justify',
  display,
}: CaptionTypographyProps) {
  return (
    <Typography
      align={align}
      component={component}
      lineHeight={lineHeight}
      color={color}
      fontSize={fontSize}
      variant="caption"
      display={display}
    >
      {children}
    </Typography>
  );
}

export default CaptionTypography;
