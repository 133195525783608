import { useSelector } from 'react-redux';

import { selectContentSet, selectVerbWords } from '@glass/shared/modules/content/contentReducer';
import isContentWordEqual from '@glass/shared/modules/content/staticVariations/isContentWordEqual';
import usePageData from '@glass/shared/modules/pageData/usePageData';

const useVerbWords = () => {
  const reduxResumeWords = useSelector(selectVerbWords, isContentWordEqual);
  const isContentSet = useSelector(selectContentSet);
  const { words } = usePageData() || {};
  return !isContentSet && words?.verbWords ? words.verbWords : reduxResumeWords;
};

export default useVerbWords;
