import formatNumberDecimals from '@glass/common/modules/utils/formatNumberDecimals';

export const createCurrencyFormatterString = (
  amount: number | string,
  currency: string,
  locale: string,
  decimals: number,
  { isDisclaimer = false }: { isDisclaimer?: boolean } = {},
) => `$(:formatCurrency{${amount},${currency},${locale},${decimals}${isDisclaimer ? ',1' : ''}})`;

const formatCurrencyExtend = (
  amountStr: string,
  currency: string,
  locale: string,
  decimalsStr: string,
  isDisclaimer: string,
) =>
  `${new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(Number(formatNumberDecimals(amountStr, { decimals: Number(decimalsStr) })))}${
    isDisclaimer ? ` (${currency.toLocaleUpperCase()})` : ''
  }`;

export default formatCurrencyExtend;
