import setAuthAction from '@glass/shared/modules/auth/setAuthAction';

const signInAuthAction =
  (auth) =>
  (dispatch, getState, { tracking }) => {
    const result = dispatch(setAuthAction(auth));
    tracking.login(auth.user);
    return result;
  };

export default signInAuthAction;
