import { useSelector } from 'react-redux';

import { selectContentSet, selectContentWords } from '@glass/shared/modules/content/contentReducer';
import isContentWordEqual from '@glass/shared/modules/content/staticVariations/isContentWordEqual';
import usePageData from '@glass/shared/modules/pageData/usePageData';

const useContentWords = () => {
  const reduxResumeWords = useSelector(selectContentWords, isContentWordEqual);
  const isContentSet = useSelector(selectContentSet);
  const { words } = usePageData() || {};
  return !isContentSet && words?.contentWords ? words.contentWords : reduxResumeWords;
};

export default useContentWords;
