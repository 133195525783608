import { CENTER, DEFAULT } from '@glass/web/modules/theme/constants';

export const FLEX = 'flex';
export const COLUMN = 'column';
export const SPACE_BETWEEN = 'space-between';
export const SPACE_AROUND = 'space-around';
export const FLEX_START = 'flex-start';
export const FLEX_END = 'flex-end';

const builderOptions = [DEFAULT, CENTER, SPACE_BETWEEN, SPACE_AROUND];
export default builderOptions;
