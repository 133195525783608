export const RESET_PASSWORD_EMAIL = 'reset-password';
export const ROCKET_AUTH_LINK_EMAIL = 'rocket-link';
export const SAVE_PROGRESS_EMAIL = 'save-progress';
export const SUBSCRIPTION_SET_TO_CANCEL_EMAIL = 'subscription-set-to-cancel';
export const SUBSCRIPTION_CANCELED_EMAIL = 'subscription-canceled';
export const DISPUTE_NEEDS_RESPONSE_EMAIL = 'dispute-needs-response';
const RETARGET = 'retarget';
export const DEPRECATED_RETARGET_RESUME_EMAIL_1 = `${RETARGET}-resume`;
export const RETARGET_RESUME_EMAIL_1 = `${RETARGET}-1`;
export const RETARGET_RESUME_EMAIL_2 = `${RETARGET}-2`;
export const RETARGET_RESUME_EMAIL_3 = `${RETARGET}-3`;
export const RETARGET_RESUME_EMAIL_4 = `${RETARGET}-4`;
export const RETARGET_RESUME_EMAIL_5 = `${RETARGET}-5`;
export const RETARGET_RESUME_EMAIL_6 = `${RETARGET}-6`;
export const RETARGET_RESUME_EMAIL_7 = `${RETARGET}-7`;
export const RETARGET_RESUME_EMAIL_8 = `${RETARGET}-8`;
export const RETARGET_RESUME_EMAIL_9 = `${RETARGET}-9`;
export const RETARGET_RESUME_EMAIL_10 = `${RETARGET}-10`;
export const RETARGET_RESUME_EMAIL_11 = `${RETARGET}-11`;
export const RETARGET_RESUME_EMAIL_12 = `${RETARGET}-12`;
export const TRIAL_END = 'trial-end';
export const WELCOME_EMAIL = 'welcome';
export const PURCHASE_EMAIL = 'purchase';
