import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useBlockClick from '@glass/web/components/Link/useBlockClick';
import MuiLink from '@glass/web/components/base/Link';

import Tracking from '@glass/shared/modules/tracking/Tracking';
import useFunctionTracking from '@glass/shared/modules/tracking/useFunctionTracking';

const Link = React.forwardRef(
  (
    {
      href,
      as,
      replace,
      trackProps: trackPropsProp,
      trackContext,
      passHref,
      children,
      preventBlock,
      onClick,
      shallow,
      params,
      prefetch,
      basePath1,
      basePath2,
      basePath3,
      className,
      target,
      to,
      industrySlug,
      jobTitleSlug,
      industries,
      itemProp,
      itemID,
      tabIndex,
      role,
      'aria-disabled': ariaDisabled,
      'aria-label': ariaLabel,
      disabled,
      'data-test-id': dataTestId,
      scroll,
      mt,
      type,
      autoFocus,
      fullWidth,
      color,
      style,
      onMouseEnter,
      onMouseLeave,
      underline,
      id,
      inline,
      disableElevation,
      ...other
    },
    ref,
  ) => {
    const { handleClick, isBlocked } = useBlockClick(onClick, { preventBlock });

    const trackProps = useMemo(
      () => ({
        action: 'link',
        ...(trackPropsProp || {}),
        href,
        preventBlock,
        isBlocked,
      }),
      [href, isBlocked, preventBlock, trackPropsProp],
    );

    if (__DEV__) {
      const keys = Object.keys(other).filter((key) => key.match(/^(?!on).+/));
      if (keys.length) {
        console.error(
          `${
            as || href?.pathname || href || to
          } | Extraneous keys ${keys.join()} add it to the props in Link`,
        );
      }
    }

    const onClickTracked = useFunctionTracking(Tracking.EVENTS.CLICK, trackProps, handleClick);

    return (
      <MuiLink
        ref={ref}
        aria-disabled={ariaDisabled}
        aria-label={ariaLabel}
        as={as}
        className={className}
        color={color}
        component={NextLink}
        data-test-id={dataTestId}
        href={href}
        id={id}
        itemID={itemID}
        itemProp={itemProp}
        passHref={passHref}
        prefetch={!prefetch ? false : undefined} // prefetch is the default behavior and when its true there is a warning
        replace={replace}
        role={role}
        scroll={scroll}
        shallow={shallow}
        style={style}
        tabIndex={tabIndex}
        target={target}
        underline={underline}
        onClick={onClickTracked}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </MuiLink>
    );
  },
);

Link.defaultProps = {
  preventBlock: false,
  color: 'inherit',
};

Link.propTypes = {
  'aria-disabled': PropTypes.bool,
  'aria-label': PropTypes.string,
  as: PropTypes.string,
  autoFocus: PropTypes.bool,
  basePath1: PropTypes.string,
  basePath2: PropTypes.string,
  basePath3: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  'data-test-id': PropTypes.string,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  industries: PropTypes.array,
  industrySlug: PropTypes.string,
  inline: PropTypes.bool,
  itemID: PropTypes.string,
  itemProp: PropTypes.string,
  jobTitleSlug: PropTypes.string,
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  passHref: PropTypes.bool,
  prefetch: PropTypes.bool,
  preventBlock: PropTypes.bool,
  replace: PropTypes.bool,
  role: PropTypes.string,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  target: PropTypes.string,
  to: PropTypes.string,
  trackContext: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  type: PropTypes.string,
  underline: PropTypes.string,
};

Link.displayName = 'Link';

export default Link;
