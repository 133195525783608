import { useMemo } from 'react';

import useExtendTextData from '@glass/shared/modules/content/useExtendTextData';

const useExtendText = (str) => {
  const { extendText } = useExtendTextData();
  return useMemo(() => extendText(str), [extendText, str]);
};

export default useExtendText;
