import uniq from 'lodash/uniq';
import React, { useMemo } from 'react';

import makeArray from '@glass/common/modules/utils/makeArray';

import TrackingContext from '@glass/shared/components/App/context/TrackingContext';
import useTracking from '@glass/shared/modules/tracking/useTracking';

// when using this a tracking prop of context is passed down and sent with every event that uses tracking from useTracking

// todo: does trackProps need to be returned to the child?
const withTrackingContext = (
  Component,
  defaultTrackingContext,
  { contextPropKey = 'trackContext' } = {},
) =>
  React.forwardRef((props, ref) => {
    const trackingParent = useTracking();

    const otherContext = props[contextPropKey];

    const trackingContexts = useMemo(
      () =>
        uniq(
          [...makeArray(defaultTrackingContext), otherContext].filter(
            (val) => typeof val === 'string',
          ),
        ),
      [otherContext],
    );

    if (!trackingContexts.length) {
      throw new Error(
        `trackingContexts is required for withTrackingContext at ${Component.displayName}`,
      );
    }

    const tracking = useMemo(
      () => trackingParent.createChild({ context: trackingContexts }),
      [trackingContexts, trackingParent],
    );

    return (
      <TrackingContext.Provider value={tracking}>
        <Component ref={ref} {...props} />
      </TrackingContext.Provider>
    );
  });

export default withTrackingContext;
