// todo: remove lodash on client
import get from 'lodash/get';

const paramsRegex = /[{}]/;

const keyRegex = /\$\(([\S]+)\)/g;

const extendText = (
  textWithKeys: unknown,
  data: unknown,
  modifiers: Record<string, (...args: string[]) => string> = {},
) => {
  if (typeof textWithKeys !== 'string') {
    return textWithKeys;
  }

  try {
    return textWithKeys.replace(keyRegex, (_, matchedKey: string) => {
      const [key, ...actionKeys] = matchedKey.split(':');
      const dataValue = key ? (get(data, key, '') as string) : key;
      return actionKeys.reduce((value, actionKeyProp) => {
        if (modifiers[actionKeyProp]) {
          const modifier = modifiers[actionKeyProp];
          if (typeof modifier === 'function') {
            return value ? modifier(value) : modifier();
          }
        } else {
          const [actionKeyWithParams, params] = actionKeyProp.split(paramsRegex) || [];
          const modifierForParams = modifiers[actionKeyWithParams];
          if (params && typeof modifierForParams === 'function') {
            const splitParams = params.split(',');
            return value
              ? modifierForParams(value, ...splitParams)
              : modifierForParams(...splitParams);
          }
        }
        return value;
      }, dataValue);
    });
  } catch (error) {
    return textWithKeys;
  }
};

export default extendText;
