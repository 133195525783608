import React from 'react';
import xss from 'xss';

type SafeHtmlProps = {
  children: React.ReactNode;
  component?: any;
  className?: any;
};

function SafeHtml({ component: Component = 'span', children, className }: SafeHtmlProps) {
  if (typeof children === 'string') {
    return <Component className={className} dangerouslySetInnerHTML={{ __html: xss(children) }} />;
  }
  return <Component className={className}>{children}</Component>;
}

export default React.memo(SafeHtml);
